<template>
  <div class="container mescroll-touch">
    <div class="header">
      <!-- <div class="plusBox">
        <img class="add" src="@/assets/images/add.png" @click="toVideoUpload">
      </div> -->
      <div class="titleBox">
        <div class="titleItem"><span :class="{'active': type == 1}" @click="changeTitle(1)">东区</span></div>
        <div class="titleItem"><span :class="{'active': type == 2}" @click="changeTitle(2)">南区</span></div>
        <div class="titleItem"><span :class="{'active': type == 3}" @click="changeTitle(3)">西区</span></div>
        <div class="titleItem"><span :class="{'active': type == 4}" @click="changeTitle(4)">北区</span></div>
        <div class="titleItem"><span :class="{'active': type == 5}" @click="changeTitle(5)">CV南北</span></div>
        <div class="titleItem"><span :class="{'active': type == 6}" @click="changeTitle(6)">CV东西</span></div>
      </div>
    </div>
    <div class="filterBox">
      <span :class="{'active': ModuleType == 2}" @click="changeModuleType(2)">最新</span>
      <span :class="{'active': ModuleType == 1}" @click="changeModuleType(1)">最热</span>
    </div>
    <mescroll-vue ref="mescroll" :down="mescrollDown" :up="mescrollUp" @init="mescrollInit">
      <div id="dataList">
        <waterfall :col="2" :width="itemWidth" :gutterWidth="gutterWidth" :data="list">
          <template>
            <div class="list" v-for="(item, index) in list" :key="index" @click="toContentPage(item.Id)">
              <div class="item_img">
                <img class="item_cover" :src="item.Thumbnail" />
              </div>
              <div class="item_content">
                <span class="item_title">{{item.Title}}</span>
                <div class="item_nums">
                  <p class="item_name">{{item.CreateUser}}</p>
                  <div class="nums">
                    <img src="@/assets/images/like.png" />
                    <span>{{item.LikeCount}}</span>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </waterfall>
      </div>
    </mescroll-vue>
    <div class="release" @click="toVideoUpload">去发布</div>
  </div>
</template>

<script>
import MescrollVue from 'mescroll.js/mescroll.vue'
export default {
  data() {
    return {
      type: 1,
      list: [],
      PageIndex: 0,
      PageSize: 8,
      ModuleType: 2,
      mescroll: null, // mescroll实例对象
      mescrollDown:{
        use: false,
        isLock: false
      },
      mescrollUp: { // 上拉加载的配置.
        isBounce: false,
			  callback: this.upCallback, // 上拉回调
				page: {
					num: 0, //当前页 默认0,回调之前会加1; 即callback(page)会从1开始
					size: 6 //每页数据条数,默认10
				},
        noMoreSize: 0,
				htmlNodata: '<p class="upwarp-nodata">-- END --</p>',
        toTop: {
          warpId : 'toTop',
          src: './static/image/top_comm_icon@2x.png' // 回到顶部按钮的图片路径,支持网络图
        },
        empty: {
          // 列表第一页无任何数据时,显示的空提示布局; 需配置warpId才生效;
          warpId: 'dataList', // 父布局的id;
          icon: './static/image/none_comm_png@2x.png', // 图标,支持网络图
          tip: '敬请期待', // 提示
        }
			},
      scrollTop: 0
    }
  },
  components: {
    MescrollVue
  },
  computed: {
    itemWidth() {
      return (340 * 0.5 * (document.documentElement.clientWidth / 375))
    },
    gutterWidth() {
      return (20 * 0.5 * (document.documentElement.clientWidth / 375))
    }
  },
  created() {
    this.$store.commit('changeNavbarIndex', 1);
  },
  activated() {
    this.$refs.mescroll.$el.scrollTop = this.scrollTop;
  },
  methods: {
    toVideoUpload() {
      this.$router.push('videoUpload');
    },
    changeTitle(type) {
      this.type = type;
      this.list = [];
      this.mescroll.resetUpScroll() // 刷新列表数据
    },
    changeModuleType(type) {
      this.ModuleType = type;
      this.list = [];
      this.mescroll.resetUpScroll() // 刷新列表数据
    },
    // mescroll组件初始化的回调,可获取到mescroll对象
		mescrollInit (mescroll) {
			this.mescroll = mescroll  // 如果this.mescroll对象没有使用到,则mescrollInit可以不用配置
		},
    // 上拉回调
		upCallback (page) {
      this.getList(page.num, page.size);
		},
    getList(PageIndex, PageSize) {
      this.PageIndex = PageIndex;
      this.PageSize = PageSize;
      setTimeout(() => {
        this.$axios.post('/Api/Api/Web/Article/GetArtList', {
          Type: this.type,
          ModuleType: this.ModuleType,
          PageIndex: this.PageIndex,
          PageSize: this.PageSize
        }).then((res) => {
          let arr = res.Data.List;
          // 如果是第一页需手动置空列表
          if (PageIndex === 1) this.list = []
          // 把请求到的数据添加到列表
          this.list = this.list.concat(arr);
          // 数据渲染成功后,隐藏下拉刷新的状态
          this.$nextTick(() => {
            this.mescroll.endSuccess(arr.length)
          })
        }).catch((e) => {
          // 联网失败的回调,隐藏下拉刷新和上拉加载的状态;
          this.mescroll.endErr()
        })
      }, 200);
    },
    toContentPage(Id) {
      this.scrollTop = this.$refs.mescroll.$el.scrollTop;
      this.$router.push({
        path: 'videoDetail',
        query: {
          id: Id
        }
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  min-height: 100%;
  padding: 45px 10px 50px;
  box-sizing: border-box;
  background-color: #FAFAFA;
  .header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    height: 45px;
    border-bottom: 1px solid #eeeeee;
    padding: 0 10px;
    box-sizing: border-box;
    .plusBox {
      .add {
        display: block;
        width: 24px;
      }
    }
    .titleBox {
      display: flex;
      justify-content: space-between;
      flex: 1;
      .titleItem {
        font-size: 15px;
        font-weight: bold;
        color: #333333;
        text-align: center;
        span {
          display: inline-block;
          height: 22px;
          line-height: 22px;
          border-radius: 22px;
          padding: 0 8px;
        }
        .active {
          background-color: #EA1600;
          color: #FFFFFF;
        }
      }
    }
  }
  .filterBox {
    display: flex;
    justify-content: center;
    font-size: 14px;
    height: 35px;
    line-height: 35px;
    span:not(:last-of-type) {
      margin-right: 30px;
    }
    .active {
      font-weight: bold;
    }
  }
  .mescroll {
    position: fixed;
    top: 80px;
    bottom: 50px;
    height: auto;
  }
  #dataList {
    // margin-top: 15px;
  }
  .list {
    width: 100%;
    background-color: #FFFFFF;
    border-radius: 4px;
    overflow: hidden;
    margin-bottom: 10px;
    .item_img {
      position: relative;
      .item_cover {
        display: block;
        width: 100%;
      }
      .playIcon {
        position: absolute;
        right: 12px;
        bottom: 12px;
        width: 30px;
        height: 30px;
      }
    }
    .item_content {
      padding: 5px 8px 5px;
      .item_title {
        height: 38px;
        font-size: 14px;
        color: #333333;
        overflow: hidden;
        text-overflow:ellipsis;
        display:-webkit-box;
        -webkit-box-orient:vertical;
        -webkit-line-clamp:2;
        word-wrap: break-word;
      }
      .item_name {
        font-size: 12px;
        color: #5B5B5B;
        line-height: 24px;
      }
      .item_label {
        display: flex;
        flex-wrap: wrap;
        span {
          display: inline-block;
          height: 20px;
          line-height: 20px;
          border-radius: 2px;
          padding: 0 6px;
          margin-right: 6px;
          margin-bottom: 5px;
          background-color: rgba(255, 73, 98, 0.15);
          font-size: 11px;
          color: #CA001B;
        }
      }
      .item_nums {
        display: flex;
        justify-content: space-between;
        margin-top: 5px;
        .nums {
          display: flex;
          align-items: center;
          img {
            width: 14px;
            margin: 0 2px 0 6px;
          }
          span {
            font-size: 12px;
            color: #5B5B5B;
          }
        }
      }
    }
  }
  .release {
    position: fixed;
    bottom: 70px;
    right: 12px;
    width: 50px;
    height: 50px;
    line-height: 50px;
    border-radius: 50%;
    text-align: center;
    font-size: 13px;
    color: #FFFFFF;
    background-color: #CA001B;
  }
}
</style>